export const localscoreConstants = {
    GETALL_CRICKET_SCORE_REQUEST: 'GETALL_CRICKET_SCORE_REQUEST',
    GETALL_CRICKET_SCORE_SUCCESS: 'GETALL_CRICKET_SCORE_SUCCESS',
    GETALL_CRICKET_SCORE_FAILURE: 'GETALL_CRICKET_SCORE_FAILURE',

    GETALL_PLAYER_LIST_REQUEST: 'GETALL_PLAYER_LIST_REQUEST',
    GETALL_PLAYER_LIST_SUCCESS: 'GETALL_PLAYER_LIST_SUCCESS',
    GETALL_PLAYER_LIST_FAILURE: 'GETALL_PLAYER_LIST_FAILURE',


    GETALL_LOCAL_MATCH_LIST_REQUEST: 'GETALL_LOCAL_MATCH_LIST_REQUEST',
    GETALL_LOCAL_MATCH_LIST_SUCCESS: 'GETALL_LOCAL_MATCH_LIST_SUCCESS',
    GETALL_LOCAL_MATCH_LIST_FAILURE: 'GETALL_LOCAL_MATCH_LIST_FAILURE',

    UPDATE_SCORE_STATUS_REQUEST: 'UPDATE_SCORE_STATUS_REQUEST',
    UPDATE_SCORE_STATUS_SUCCESS: 'UPDATE_SCORE_STATUS_SUCCESS',
    UPDATE_SCORE_STATUS_FAILURE: 'UPDATE_SCORE_STATUS_FAILURE',

    GET_LOCAL_MATCHDETAIL_STATUS_REQUEST: 'GET_LOCAL_MATCHDETAIL_STATUS_REQUEST',
    GET_LOCAL_MATCHDETAIL_STATUS_SUCCESS: 'GET_LOCAL_MATCHDETAIL_STATUS_SUCCESS',
    GET_LOCAL_MATCHDETAIL_STATUS_FAILURE: 'GET_LOCAL_MATCHDETAIL_STATUS_FAILURE',
};
