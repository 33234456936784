export const teamConstants = {
    PLAYER_IMAGE_UPLOAD_SUCCESS: 'PLAYER_IMAGE_UPLOAD_SUCCESS',
    PLAYER_IMAGE_UPLOAD_FAILURE: 'PLAYER_IMAGE_UPLOAD_FAILURE',

    PLAYER_ADD_SUCCESS: 'PLAYER_ADD_SUCCESS',
    PLAYER_ADD_FAILURE: 'PLAYER_ADD_FAILURE',

    PLAYER_GET_REQUEST: 'PLAYER_GET_REQUEST',
    PLAYER_GET_SUCCESS: 'PLAYER_GET_SUCCESS',
    PLAYER_GET_FAILURE: 'PLAYER_GET_FAILURE',

    GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
    GET_COUNTRY_FAILURE: 'GET_COUNTRY_FAILURE',

    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',
};
