export const subadminConstants = {
    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',

    SUB_ADMIN_ADD_SUCCESS: 'SUB_ADMIN_ADD_SUCCESS',
    SUB_ADMIN_ADD_FAILURE: 'SUB_ADMIN_ADD_FAILURE',

    SUB_ADMIN_DELETE_SUCCESS: 'SUB_ADMIN_DELETE_SUCCESS',
    SUB_ADMIN_DELETE_FAILURE: 'SUB_ADMIN_DELETE_FAILURE',

    GET_RESOUCE_SUCCESS: 'GET_RESOUCE_SUCCESS',
    GET_RESOUCE_FAILURE: 'GET_RESOUCE_FAILURE',

    SAVE_ASSIGNED_RESOUCE_SUCCESS: 'SAVE_ASSIGNED_RESOUCE_SUCCESS',
    SAVE_ASSIGNED_RESOUCE_FAILURE: 'SAVE_ASSIGNED_RESOUCE_FAILURE',

    GET_ASSIGNED_RESOUCE_SUCCESS: 'GET_ASSIGNED_RESOUCE_SUCCESS',
    GET_ASSIGNED_RESOUCE_FAILURE: 'GET_ASSIGNED_RESOUCE_FAILURE',

    UPDATE_STATUS_REQUEST: 'UPDATE_STATUS_REQUEST',
    UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
    UPDATE_STATUS_FAILURE: 'UPDATE_STATUS_FAILURE',

    GET_SELMENU_REQUEST: 'GET_SELMENU_REQUEST',
    GET_SELMENU_SUCCESS: 'GET_SELMENU_SUCCESS',
    GET_SELMENU_FAILURE: 'GET_SELMENU_FAILURE',
};
