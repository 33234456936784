export const promocodeConstants = {
    GETALL_PROMOCODE_REQUEST: 'GETALL_PROMOCODE_REQUEST',
    GETALL_PROMOCODE_SUCCESS: 'GETALL_PROMOCODE_SUCCESS',
    GETALL_PROMOCODE_FAILURE: 'GETALL_PROMOCODE_FAILURE',

    UPDATE_STATUS_PROMOCODE_REQUEST: 'UPDATE_STATUS_PROMOCODE_REQUEST',
    UPDATE_STATUS_PROMOCODE_SUCCESS: 'UPDATE_STATUS_PROMOCODE_SUCCESS',
    UPDATE_STATUS_PROMOCODE_FAILURE: 'UPDATE_STATUS_PROMOCODE_FAILURE',

    PROMOCODE_SETTINGS_REQUEST: 'PROMOCODE_SETTINGS_REQUEST',
    PROMOCODE_SETTINGS_SUCCESS: 'PROMOCODE_SETTINGS_SUCCESS',
    PROMOCODE_SETTINGS_FAILURE: 'PROMOCODE_SETTINGS_FAILURE',
};
