export const cricketConstants = {
  CRICKET_MATCH_GETALL_REQUEST: 'CRICKET_MATCH_GETALL_REQUEST',
  CRICKET_MATCH_GETALL_SUCCESS: 'CRICKET_MATCH_GETALL_SUCCESS',
  CRICKET_MATCH_GETALL_FAILURE: 'CRICKET_MATCH_GETALL_FAILURE',

  GETALL_SUCCESS_TEAM: 'GETALL_SUCCESS_TEAM',
  TEAM_REQUEST: 'TEAM_REQUEST',
  TEAM_GET_SUCCESS: 'TEAM_GET_SUCCESS',
  TEAM_GET_FAILURE: 'TEAM_GET_FAILURE',
  TEAM_ADD_SUCCESS: 'TEAM_ADD_SUCCESS',
  TEAM_ADD_FAILURE: 'TEAM_ADD_FAILURE',
  MATCH_DELETE_SUCCESS: 'MATCH_DELETE_SUCCESS',
  MATCH_DELETE_FAILURE: 'MATCH_DELETE_FAILURE',

  CRICKET_MATCH_TEAM_SEARCH_REQUEST: 'CRICKET_MATCH_TEAM_SEARCH_REQUEST',
  CRICKET_MATCH_TEAM_SEARCH_SUCCESS: 'CRICKET_MATCH_TEAM_SEARCH_SUCCESS',
  CRICKET_MATCH_TEAM_SEARCH_FAILURE: 'CRICKET_MATCH_TEAM_SEARCH_FAILURE',

  GET_CRICKET_MATCH_TEAM_PLAYER_LIST_REQUEST1:
    'GET_CRICKET_MATCH_TEAM_PLAYER_LIST_REQUEST1',
  GET_CRICKET_MATCH_TEAM_PLAYER_LIST_SUCCESS1:
    'GET_CRICKET_MATCH_TEAM_PLAYER_LIST_SUCCESS1',
  GET_CRICKET_MATCH_TEAM_PLAYER_LIST_FAILURE1:
    'GET_CRICKET_MATCH_TEAM_PLAYER_LIST_FAILURE',

  GET_CRICKET_MATCH_TEAM_PLAYER_LIST_REQUEST2:
    'GET_CRICKET_MATCH_TEAM_PLAYER_LIST_REQUEST2',
  GET_CRICKET_MATCH_TEAM_PLAYER_LIST_SUCCESS2:
    'GET_CRICKET_MATCH_TEAM_PLAYER_LIST_SUCCESS2',
  GET_CRICKET_MATCH_TEAM_PLAYER_LIST_FAILURE2:
    'GET_CRICKET_MATCH_TEAM_PLAYER_LIST_FAILURE2',

  GET_CRICKET_MATCH_LIST_API_REQUEST: 'GET_CRICKET_MATCH_LIST_API_REQUEST',
  GET_CRICKET_MATCH_LIST_API_SUCCESS: 'GET_CRICKET_MATCH_LIST_API_SUCCESS',
  GET_CRICKET_MATCH_LIST_API_FAILURE: 'GET_CRICKET_MATCH_LIST_API_FAILURE',

  /**
   * @Constant for addteam in cricket component
   */

  CRICKET_TEAM_ADD_SUCCESS: 'CRICKET_TEAM_ADD_SUCCESS',
  CRICKET_TEAM_ADD_FAILURE: 'CRICKET_TEAM_ADD_FAILURE',

  CRICKET_TEAM_GET_SUCCESS: 'CRICKET_TEAM_GET_SUCCESS',
  CRICKET_TEAM_GET_FAILURE: 'CRICKET_TEAM_GET_FAILURE',

  CRICKET_PLAYER_ADD_SUCCESS: 'CRICKET_PLAYER_ADD_SUCCESS',
  CRICKET_PLAYER_ADD_FAILURE: 'CRICKET_PLAYER_ADD_FAILURE',

  CRICKET_PLAYER_GETALL_REQUEST: 'CRICKET_PLAYER_GETALL_REQUEST',
  CRICKET_PLAYER_GETALL_SUCCESS: 'CRICKET_PLAYER_GETALL_SUCCESS',
  CRICKET_PLAYER_GETALL_FAILURE: 'CRICKET_PLAYER_GETALL_FAILURE',

  CRICKET_MATCH_ADD_SUCCESS: 'CRICKET_MATCH_ADD_SUCCESS',
  CRICKET_MATCH_ADD_FAILURE: 'CRICKET_MATCH_ADD_FAILURE',

  GET_CONTESTS_SUCCESS: 'GET_CONTESTS_SUCCESS',
  GET_CONTESTS_FAILURE: 'GET_CONTESTS_FAILURE',

  CRICKET_CONTESTS_ADD_SUCCESS: 'CRICKET_CONTESTS_ADD_SUCCESS',
  CRICKET_CONTESTS_ADD_FAILURE: 'CRICKET_CONTESTS_ADD_FAILURE',

  CRICKET_CONTESTS_POOL_LIST_SUCCESS: 'CRICKET_CONTESTS_POOL_LIST_SUCCESS',
  CRICKET_CONTESTS_POOL_LIST_FAILURE: 'CRICKET_CONTESTS_POOL_LIST_FAILURE',

  CRICKET_CONTESTS_POOL_ADD_SUCCESS: 'CRICKET_CONTESTS_POOL_ADD_SUCCESS',
  CRICKET_CONTESTS_POOL_ADD_FAILURE: 'CRICKET_CONTESTS_POOL_ADD_FAILURE',

  CRICKET_CONTESTS_PRIZE_ADD_SUCCESS: 'CRICKET_CONTESTS_PRIZE_ADD_SUCCESS',
  CRICKET_CONTESTS_PRIZE_ADD_FAILURE: 'CRICKET_CONTESTS_PRIZE_ADD_FAILURE',

  CRICKET_CONTESTS_PRIZE_GET_SUCCESS: 'CRICKET_CONTESTS_PRIZE_GET_SUCCESS',
  CRICKET_CONTESTS_PRIZE_GET_FAILURE: 'CRICKET_CONTESTS_PRIZE_GET_FAILURE',

  CRICKET_CONTESTS_MATCH_SHOW_SUCCESS: 'CRICKET_CONTESTS_MATCH_SHOW_SUCCESS',
  CRICKET_CONTESTS_MATCH_SHOW_FAILURE: 'CRICKET_CONTESTS_MATCH_SHOW_FAILURE',


  CRICKET_CONTESTS_ASSIGN_MATCH_SHOW_SUCCESS: 'CRICKET_CONTESTS_ASSIGN_MATCH_SHOW_SUCCESS',
  CRICKET_CONTESTS_ASSIGN__MATCH_SHOW_FAILURE: 'CRICKET_CONTESTS_ASSIGN__MATCH_SHOW_FAILURE',

  CRICKET_UPDATE_STATUS_CONTESTS_ASSIGN_MATCH_SHOW_SUCCESS: 'CRICKET_UPDATE_STATUS_CONTESTS_ASSIGN__MATCH_SHOW_SUCCESS',
  CRICKET_UPDATE_STATUS_CONTESTS_ASSIGN__MATCH_SHOW_FAILURE: 'CRICKET_UPDATE_STATUS_CONTESTS_ASSIGN__MATCH_SHOW_FAILURE',

  Assigned_Pool_Contest_LIST_SUCCESS: 'Assigned_Pool_Contest_LIST_SUCCESS',
  Assigned_Pool_Contest_LIST_FAILURE: 'Assigned_Pool_Contest_LIST_FAILURE',

  Assigned_Pool_Contest_UPDATE_SUCCESS: 'Assigned_Pool_Contest_UPDATE_SUCCESS',
  Assigned_Pool_Contest_UPDATE_FAILURE: 'Assigned_Pool_Contest_UPDATE_FAILURE',

  PLAYER_IMAGE_LIST_BY_ID_SUCCESS: 'PLAYER_IMAGE_LIST_BY_ID_SUCCESS',
  PLAYER_IMAGE_LIST_BY_ID_FAILURE: 'PLAYER_IMAGE_LIST_BY_ID_FAILURE',

  UPCOMMING_MATCH_SUCCESS: 'UPCOMMING_MATCH_SUCCESS',
  UPCOMMING_MATCH_FAILURE: 'UPCOMMING_MATCH_FAILURE',

  ADD_DOM_UPCOMMING_MATCH_SUCCESS: 'ADD_DOM_UPCOMMING_MATCH_SUCCESS',
  ADD_DOM_UPCOMMING_MATCH_FAILURE: 'ADD_DOM_UPCOMMING_MATCH_FAILURE',

  ACTIVE_UPCOMMING_MATCH_SUCCESS: 'ACTIVE_UPCOMMING_MATCH_SUCCESS',
  ACTIVE_UPCOMMING_MATCH_FAILURE: 'ACTIVE_UPCOMMING_MATCH_FAILURE',

  CRICKET_CONTESTS_POOL_AND_PRIZE_ADD_SUCCESS: 'CRICKET_CONTESTS_POOL_AND_PRIZE_ADD_SUCCESS',
  CRICKET_CONTESTS_POOL_AND_PRIZE_ADD_FAILURE: 'CRICKET_CONTESTS_POOL_AND_PRIZE_ADD_FAILURE',

  ACTIVE_ALL_MATCH_SUCCESS: 'ACTIVE_ALL_MATCH_SUCCESS',
  ACTIVE_ALL_MATCH_FAILURE: 'ACTIVE_ALL_MATCH_FAILURE',
};