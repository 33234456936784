export const withdrawConstants = {
    GETALL_WITHDRAW_REQUEST: 'GETALL_WITHDRAW_REQUEST',
    GETALL_WITHDRAW_SUCCESS: 'GETALL_WITHDRAW_SUCCESS',
    GETALL_WITHDRAW_FAILURE: 'GETALL_WITHDRAW_FAILURE',


    UPDATE_STATUS_WITHDRAW_REQUEST: 'UPDATE_STATUS_WITHDRAW_REQUEST',
    UPDATE_STATUS_WITHDRAW_SUCCESS: 'UPDATE_STATUS_WITHDRAW_SUCCESS',
    UPDATE_STATUS_WITHDRAW_FAILURE: 'UPDATE_STATUS_WITHDRAW_FAILURE',
};
