export const rewardsConstants = {
    GETALL_REWARDS_REQUEST: 'GETALL_REWARDS_REQUEST',
    GETALL_REWARDS_SUCCESS: 'GETALL_REWARDS_SUCCESS',
    GETALL_REWARDS_FAILURE: 'GETALL_REWARDS_FAILURE',

    UPDATE_STATUS_REWARDS_REQUEST: 'UPDATE_STATUS_REWARDS_REQUEST',
    UPDATE_STATUS_REWARDS_SUCCESS: 'UPDATE_STATUS_REWARDS_SUCCESS',
    UPDATE_STATUS_REWARDS_FAILURE: 'UPDATE_STATUS_REWARDS_FAILURE',

    REWARDS_SETTINGS_REQUEST: 'REWARDS_SETTINGS_REQUEST',
    REWARDS_SETTINGS_SUCCESS: 'REWARDS_SETTINGS_SUCCESS',
    REWARDS_SETTINGS_FAILURE: 'REWARDS_SETTINGS_FAILURE',

    GETALL_LEVELS_REQUEST: 'GETALL_LEVELS_REQUEST',
    GETALL_LEVELS_SUCCESS: 'GETALL_LEVELS_SUCCESS',
    GETALL_LEVELS_FAILURE: 'GETALL_LEVELS_FAILURE',

    UPDATE_STATUS_LEVELS_REQUEST: 'UPDATE_STATUS_LEVELS_REQUEST',
    UPDATE_STATUS_LEVELS_SUCCESS: 'UPDATE_STATUS_LEVELS_SUCCESS',
    UPDATE_STATUS_LEVELS_FAILURE: 'UPDATE_STATUS_LEVELS_FAILURE', 
};
