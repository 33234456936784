export const onepagereportConstants = {
    GETALL_FILTER_REQUEST: 'GETALL_FILTER_REQUEST',
    GETALL_FILTER_SUCCESS: 'GETALL_FILTER_SUCCESS',
    GETALL_FILTER_FAILURE: 'GETALL_FILTER_FAILURE',

    GETALL_MATCH_FILTER_REQUEST: 'GETALL_MATCH_FILTER_REQUEST',
    GETALL_MATCH_FILTER_SUCCESS: 'GETALL_MATCH_FILTER_SUCCESS',
    GETALL_MATCH_FILTER_FAILURE: 'GETALL_MATCH_FILTER_FAILURE',

    GETALL_CONTEST_FILTER_REQUEST: 'GETALL_CONTEST_FILTER_REQUEST',
    GETALL_CONTEST_FILTER_SUCCESS: 'GETALL_CONTEST_FILTER_SUCCESS',
    GETALL_CONTEST_FILTER_FAILURE: 'GETALL_CONTEST_FILTER_FAILURE',

    GETALL_POOL_FILTER_REQUEST: 'GETALL_POOL_FILTER_REQUEST',
    GETALL_POOL_FILTER_SUCCESS: 'GETALL_POOL_FILTER_SUCCESS',
    GETALL_POOL_FILTER_FAILURE: 'GETALL_POOL_FILTER_FAILURE',

    GETALL_ONEPAGE_DATA_FILTER_REQUEST: 'GETALL_ONEPAGE_DATA_FILTER_REQUEST',
    GETALL_ONEPAGE_DATA_FILTER_SUCCESS: 'GETALL_ONEPAGE_DATA_FILTER_SUCCESS',
    GETALL_ONEPAGE_DATA_FILTER_FAILURE: 'GETALL_ONEPAGE_DATA_FILTER_FAILURE',




    SUB_ADMIN_ADD_SUCCESS: 'SUB_ADMIN_ADD_SUCCESS',
    SUB_ADMIN_ADD_FAILURE: 'SUB_ADMIN_ADD_FAILURE',

    SUB_ADMIN_DELETE_SUCCESS: 'SUB_ADMIN_DELETE_SUCCESS',
    SUB_ADMIN_DELETE_FAILURE: 'SUB_ADMIN_DELETE_FAILURE',

    GET_RESOUCE_SUCCESS: 'GET_RESOUCE_SUCCESS',
    GET_RESOUCE_FAILURE: 'GET_RESOUCE_FAILURE',

    SAVE_ASSIGNED_RESOUCE_SUCCESS: 'SAVE_ASSIGNED_RESOUCE_SUCCESS',
    SAVE_ASSIGNED_RESOUCE_FAILURE: 'SAVE_ASSIGNED_RESOUCE_FAILURE',

    GET_ASSIGNED_RESOUCE_SUCCESS: 'GET_ASSIGNED_RESOUCE_SUCCESS',
    GET_ASSIGNED_RESOUCE_FAILURE: 'GET_ASSIGNED_RESOUCE_FAILURE',

    UPDATE_STATUS_REQUEST: 'UPDATE_STATUS_REQUEST',
    UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
    UPDATE_STATUS_FAILURE: 'UPDATE_STATUS_FAILURE',

    GET_TEAM_PLAYER_LIST_REQUEST: 'GET_TEAM_PLAYER_LIST_REQUEST',
    GET_TEAM_PLAYER_LIST_SUCCESS: 'GET_TEAM_PLAYER_LIST_SUCCESS',
    GET_TEAM_PLAYER_LIST_FAILURE: 'GET_TEAM_PLAYER_LIST_FAILURE',

    GETALL_TEAM_PLAYER_LIST_REQUEST: 'GETALL_TEAM_PLAYER_LIST_REQUEST',
    GETALL_TEAM_PLAYER_LIST_SUCCESS: 'GETALL_TEAM_PLAYER_LIST_SUCCESS',
    GETALL_TEAM_PLAYER_LIST_FAILURE: 'GETALL_TEAM_PLAYER_LIST_FAILURE',

    UPDATE_TEAM_PLAYER_REQUEST: 'UPDATE_TEAM_PLAYER_REQUEST',
    UPDATE_TEAM_PLAYER_SUCCESS: 'UPDATE_TEAM_PLAYER_SUCCESS',
    UPDATE_TEAM_PLAYER_FAILURE: 'UPDATE_TEAM_PLAYER_FAILURE',
 
};
