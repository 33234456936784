export const paymentConstants = {

    BANK_GETALL_REQUEST: 'BANK_GETALL_REQUEST',
    BANK_GETALL_SUCCESS: 'BANK_GETALL_SUCCESS',
    BANK_GETALL_FAILURE: 'BANK_GETALL_FAILURE',


    PANCARD_GETALL_REQUEST: 'PANCARD_GETALL_REQUEST',
    PANCARD_GETALL_SUCCESS: 'PANCARD_GETALL_SUCCESS',
    PANCARD_GETALL_FAILURE: 'PANCARD_GETALL_FAILURE',
};
